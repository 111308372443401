/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("fontsource-noto-sans-jp/500.css")

exports.onInitialClientRender = () => {
  var vidDefer = document.getElementsByTagName("iframe")
  for (var i = 0; i < vidDefer.length; i++) {
    if (vidDefer[i].getAttribute("data-src")) {
      vidDefer[i].setAttribute("src", vidDefer[i].getAttribute("data-src"))
    }
  }
}
